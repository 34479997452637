import { ProColumns } from 'src/app/common/components/ProTable';
import { renderExpoButton } from '../components/talkbot-export-button/talkbot-export-button.component';
import { renderOperationHeader } from '../../pages/export-leads/export-leads-list/export-leads-list.screen';
import { CampaignStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import moment from 'moment';
import { eligibleLeadsGaDataClick3, exportToAiTalkbotDialogGaDataClick } from 'src/app/common/ga/types/ga-talkbot';

export const getExportOperation = (t: (key: string, values?: Record<string, any>) => string, showTooltip?: boolean) => {
  return {
    title: !showTooltip ? 'section.common.operation' : renderOperationHeader(t),
    sticky: true,
    dataIndex: 'operation',
    width: '176px',
    render: (currValue: string, item: any, actions: any) =>
      renderExpoButton({
        item,
        actions,
        showAcceptedToast: showTooltip,
        gaData: { button: eligibleLeadsGaDataClick3, confirm: exportToAiTalkbotDialogGaDataClick },
      }),
  };
};

export const addExportToColumn = (
  columns: any[],
  t: (key: string, values?: Record<string, any>) => string,
  showTooltip?: boolean,
): ProColumns<any>[] => {
  const newColumn = [...columns];
  const operation = getExportOperation(t, showTooltip);
  newColumn.push(operation);
  return newColumn;
};

export const groupedExportData = (data: any[]): any[] => {
  const groupedData = data?.reduce((acc, curr) => {
    const key = curr?.campaign?.talkbotRule?.flowId;
    (acc[key] ? acc[key] : (acc[key] = [])).push(curr);
    return acc;
  }, {});
  return Object.values(groupedData);
};

export const getEligibleLeads = (leads: any[], activeFlowIds?: number[]) => {
  const eligibleLeads = leads?.filter((lead) => {
    const { campaign, talkbotExported } = lead;
    return (
      campaign?.status === CampaignStatusEnum.active &&
      activeFlowIds?.includes(campaign?.talkbotRule?.flowId) &&
      !talkbotExported &&
      lead.leadRaw &&
      moment().isBetween(campaign?.startDate, campaign?.endDate)
    );
  });
  return eligibleLeads;
};

export const isBirthdayWithin30Days = (birthDate: string) => {
  const now = moment();
  const curYearBirthday = moment(birthDate).year(now.year());
  const nextYearBirthday = moment(birthDate).year(now.year() + 1);
  const nextBirthday = curYearBirthday.isBefore(now, 'day') ? nextYearBirthday : curYearBirthday;
  const future30Days = now.add(30, 'd');
  return nextBirthday.isSameOrBefore(future30Days);
};
