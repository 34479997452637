import React, { FC, memo, ReactNode, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { Box, Button, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import { useTalkbotExportButton } from './talkbot-export-button.hook';
import ButtonDialog from 'src/app/common/components/ButtonDialog';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';
import { TalkBotExportType } from '../../../types/talkbot-export-leads-types';
import PruIcon from 'src/app/common/components/PruIcon';
import { takeUIClickEvent } from 'src/app/common/ga/ga';
import { eligibleLeadsGaDataClick3 } from 'src/app/common/ga/types/ga-talkbot';
import { TakeUIClickEventParameters } from 'src/app/common/ga/types/ga-general-type';

type ComponentProps = {
  /* otherProp: string */
  item: any;
  type?: TalkBotExportType;
  actions?: (actions: ('clearRow' | 'refresh')[]) => void;
  showAcceptedToast?: boolean;
  gaData: { button: TakeUIClickEventParameters; confirm: TakeUIClickEventParameters };
};

export const renderExpoButton = (props: ComponentProps) => {
  return <TalkbotExportButtonComponent {...props} />;
};

/**
 * @description use for table operation
 */
export const TalkbotExportButtonComponent: React.FC<ComponentProps> = memo(
  ({ item, type, actions, showAcceptedToast, gaData }: ComponentProps) => {
    // i18n
    const { t } = useTranslation();
    const { classes: styles } = useStyles();

    // custom-hook
    const { onExport, loading, enableExport } = useTalkbotExportButton({ actions, type, items: [{ ...item }] });

    const isExported = useMemo(() => {
      return type === TalkBotExportType.prospect ? item.prospect.talkbotExported : item.talkbotExported;
    }, [type, item]);

    return isExported ? (
      <Button disabled sx={{ fontSize: 14, fontWeight: 700 }}>
        {t('app.button.exported')}
      </Button>
    ) : enableExport ? (
      <ButtonDialog
        btnVariant="text"
        titleTxt={t('talkbot.export_modal_title')}
        dialogTxt={<DialogContent type={type} />}
        btnTxt={t('app.button.export')}
        onConfirm={() => {
          takeUIClickEvent(gaData.confirm);
          onExport([{ ...item }], type, showAcceptedToast);
        }}
        btnClassName={styles.opButton}
        onClickButton={() => takeUIClickEvent(gaData.button)}
      />
    ) : null;
  },
);

/**
 * @description use for table multi selection action
 */
export const MultiExpoButton = ({
  selectedRows,
  onAction,
  type,
  showAcceptedToast,
  gaData,
}: ActionNodeTypeProps<any> & {
  type?: TalkBotExportType;
  showAcceptedToast?: boolean;
  gaData: { button: TakeUIClickEventParameters; confirm: TakeUIClickEventParameters };
}) => {
  const { t } = useTranslation();
  const { classes: styles } = useStyles();
  const { onExport, loading, enableExport } = useTalkbotExportButton({
    actions: onAction,
    type,
    items: selectedRows,
  });

  return (
    <ButtonDialog
      disabled={!enableExport}
      btnVariant="text"
      titleTxt={t('talkbot.export_modal_title')}
      dialogTxt={<DialogContent total={selectedRows.length} type={type} />}
      btnTxt={
        <Stack flexDirection="row" alignItems="center">
          <PruIcon
            icon="share"
            customClasses={`tw-w-[24px] tw-h-[24px] tw-inline tw-mb-[3px] ${
              enableExport ? 'tw-text-[#E8192C]' : 'tw-text-[rgba(0, 0, 0, 0.26)]'
            }`}
          />
          {t('app.button.export')}
        </Stack>
      }
      onConfirm={() => {
        takeUIClickEvent(gaData.button);
        onExport(selectedRows, type, showAcceptedToast);
      }}
      btnClassName={styles.muliSelectedButton}
      onClickButton={() => {
        takeUIClickEvent(gaData.button);
      }}
    />
  );
};

const DialogContent = ({ type, total = 1 }: { type?: TalkBotExportType; total?: number }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: 392 }}>
      <Typography sx={{ fontSize: 14, fontWeight: '400' }}>{t('talkbot.pic_tips')}</Typography>
      <Typography sx={{ fontSize: 14, fontWeight: '500', marginTop: 3, marginBottom: 3 }}>
        <PruIcon icon="tip_urgent" customClasses="tw-w-[16px] tw-h-[16px] tw-inline tw-text-[#CCCCCC]" />
        {t('talkbot.auto_call_tips')}
      </Typography>
      <Typography className="tw-text-[#e8192c]" sx={{ fontSize: 14, fontWeight: '500' }}>
        {type === TalkBotExportType.prospect
          ? t('talkbot.export_leads.total_selected_prospect_records')
          : t('talkbot.export_leads.total_selected_lead_records')}
        : {total}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  opButton: {
    fontSize: 14,
    fontWeight: 700,
    color: '#4386E5',
  },
  muliSelectedButton: {
    marginRight: 20,
    fontSize: 14,
    fontWeight: 700,
    color: '#E8192C',
  },
}));
